<template>
  <div class="project">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        项目管理
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix" style="overflow: hidden;">
        <!-- <span>卡片名称</span> -->
        <el-button type="primary" @click="handleAdd">新增项目</el-button>
        <!-- <el-button style="float: right" type="primary" @click="processagain">重置</el-button> -->
      </div>
      <!-- 当前项目信息展示 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        @row-click="handleProject">
        <el-table-column
          prop="id"
          label="项目编号"
          width="180">
        </el-table-column>
        <el-table-column
          prop="project_name"
          label="项目名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="description"
          label="项目描述">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <!-- 添加项目信息dialog弹窗 -->
    <el-dialog title="项目信息" :visible.sync="dialogFormVisibleAdd" @close="closeHandle">
      <el-form :model="dialogProjectForm" ref="dialogProjectFormRef" :rules="projectFormRules">
        <el-form-item label="项目名称" :label-width="formLabelWidth" prop="project_name">
          <el-input v-model="dialogProjectForm.project_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="项目描述" :label-width="formLabelWidth" prop="description">
          <el-input v-model="dialogProjectForm.description" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="项目类型" :label-width="formLabelWidth" prop="type">
          <el-select v-model="dialogProjectForm.type" placeholder="请选择项目类型">
            <el-option label="web网页" value="shanghai"></el-option>
            <el-option label="app移动端" value="beijing"></el-option>
            <el-option label="接口平台" value="guangzhou"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAdd">确定新增</el-button>
        <el-button v-else-if="!isAdd" type="primary" @click="processEdit">确定编辑</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getProjectList, AddProject, DeleteProject, EditProject } from '@/services/project.js'
export default {
  name: 'Project',
  data () {
    return {
      isAdd: true,
      currentId: null,
      tableData: [{
        id: '1',
        project_name: '王小虎',
        description: '上海市普陀区金沙江路 1518 弄'
      }],
      dialogFormVisibleAdd: false,
      dialogProjectForm: {
        project_name: '',
        description: '',
        type: null
      },
      formLabelWidth: '120px',
      projectFormRules: {
        project_name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入项目描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择项目类型', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.loadProjectInfo()
  },
  methods: {
    processagain () {
      this.$refs.dialogProjectFormRef.resetFields()
    },
    // 加载项目列表
    async loadProjectInfo () {
      const queryObj = {
        query_type: 'query_list',
        page: 1,
        size: 10
      }
      const { data: res } = await getProjectList(queryObj)
      console.log(res, '<--测试计划列表信息')
      this.tableData = res.data
    },
    // 弹出dialog会话框
    handleAdd () {
      this.isAdd = true
      this.dialogFormVisibleAdd = true
      console.log('ADD project', '<--打开添加测试计划Dialog面板')
    },
    // 具体请求接口实现项目新增操作
    async processAdd () {
      try {
        // 校验输入信息是否正确
        await this.$refs.dialogProjectFormRef.validate()
        // 收集用户信息
        const { project_name: projectName, description } = this.dialogProjectForm
        // 发送用户请求，确定本次新增的结果
        const { data: res } = await AddProject({
          project_name: projectName,
          description
        })
        console.log(res, '<--添加测试计划返回信息')
        if (res.status === 1) {
          this.dialogFormVisibleAdd = false
          this.$message.success('新增测试计划项目成功')
          this.$refs.dialogProjectFormRef.resetFields()
          this.loadProjectInfo()
        }
      } catch (error) {
        this.$message.warning('信息校验未通过')
      }
    },
    // 弹出dialog编辑会话框
    handleEdit (rowData) {
      this.isAdd = false
      this.currentId = rowData.id
      this.dialogFormVisibleAdd = true
      this.dialogProjectForm.project_name = rowData.project_name
      this.dialogProjectForm.description = rowData.description
    },
    async processEdit () {
      try {
        // 校验输入信息是否正确
        await this.$refs.dialogProjectFormRef.validate()
        // 收集用户信息
        // const { project_name: projectName, description } = this.dialogProjectForm
        const { data: res } = await EditProject({
          id: this.currentId,
          project_name: this.dialogProjectForm.project_name,
          description: this.dialogProjectForm.description
        })
        console.log(res, '<--编辑测试计划返回信息')
        if (res.status === 1) {
          this.dialogFormVisibleAdd = false
          this.$message.success('编辑测试计划项目信息成功')
          this.dialogProjectForm.project_name = ''
          this.dialogProjectForm.description = ''
          // 因为新增和编辑共用一个表单，此时resetFields()方法还原为初始值，看最先操作的是新增还是编辑
          // this.$refs.dialogProjectFormRef.resetFields()
          this.loadProjectInfo()
        }
      } catch (error) {
        this.$message.warning('信息校验未通过')
      }
    },
    // 关闭前操作
    closeHandle () {
      this.dialogProjectForm = {
        project_name: '',
        description: '',
        type: null
      }
      this.$refs.dialogProjectFormRef.resetFields()
    },
    // 删除测试计划项目
    async handleDel (rowData) {
      this.$confirm('此操作将永久删除测试项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        console.log(rowData, '被删测试计划信息')
        const { data: res } = await DeleteProject({
          id: rowData.id,
          project_name: rowData.project_name
        })
        console.log(res, '<--删除测试计划返回信息')
        if (res.status === 1) {
          this.$message.success('删除成功!');
          this.loadProjectInfo()
        }
      }).catch(() => {
        this.$message.success('已取消删除')
      })
    },
    handleProject (row) {
      console.log(row, '点击所在行')
      this.$router.push('/suite')
      // 将 项目ID 存储到 vuex 中
      this.$store.commit('saveProjectId', row.id)
    }
  }
}
</script>

<style>

</style>
