import request from '@/utils/request.js'

// 查询测试计划列表
export const getProjectList = (data) => {
  return request({
    method: 'GET',
    url: 'project',
    params: {
      ...data
    }
  })
}

// 查询测试项目列表全部信息
export const queryProjectAll = (data) => {
  return request({
    method: 'GET',
    url: 'project',
    params: {
      ...data
    }
  })
}

// 添加测试计划
export const AddProject = (data) => {
  return request({
    method: 'POST',
    url: 'project',
    data
  })
}

// 删除测试计划
export const DeleteProject = (data) => {
  return request({
    method: 'DELETE',
    url: 'project',
    data
  })
}

// 编辑测试计划
export const EditProject = (data) => {
  return request({
    method: 'PUT',
    url: 'project',
    data
  })
}
